import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import L from 'leaflet';
import { ButtonBase, Dialog, DialogActions, DialogContentText, DialogTitle, DialogContent, MenuItem, CircularProgress  } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PersonIcon from '@mui/icons-material/Person';
import DirectionsIcon from '@mui/icons-material/Directions';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SettingsIcon from '@mui/icons-material/Settings';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CallIcon from '@mui/icons-material/Call';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import Box from '@mui/material/Box';


const formatArrivalTime = (arrivalTime) => {
  const date = new Date(arrivalTime);

  // Add 2 hours to the time
  date.setHours(date.getHours() - 5);

  // Format the time to HH:MM
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${hours}:${minutes}`;
};

function OrderDriver({ order, onBackClick }) {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [currentLocationMarker, setCurrentLocationMarker] = useState(null);
  const [orderPointMarker, setOrderPointMarker] = useState(null);
  const [driver, setDriver] = useState(null);
  const [note, setNote] = useState('');
  const [images, setImages] = useState([]);
  const [status, setStatus] = useState(order.status);
  const [openNoteDialog, setOpenNoteDialog] = useState(false);
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formattedArrivalTime, setFormattedArrivalTime] = useState('');

  const statusOptions = ['recogido', 'cancelado', 'pospuesto'];

    const handleNoteClick = () => {
        setOpenNoteDialog(true);
    };

    const handleStatusClick = () => {
        setOpenStatusDialog(true);
    };

    const handleNoteSubmit = () => {
        setOpenNoteDialog(false);
    };

    const handleStatusSubmit = () => {
        setOpenStatusDialog(false);
    };

    useEffect(() => {
      if (order.arrival_time) {
          const adjustedTime = formatArrivalTime(order.arrival_time);
          setFormattedArrivalTime(adjustedTime);
      }
  }, [order.arrival_time]);

  function compressImage(file, quality = 0.6, maxWidth = 1000, maxHeight = 1000) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;

            img.onload = () => {
                const canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                // Maintain aspect ratio
                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height *= maxWidth / width));
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width *= maxHeight / height));
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                // Convert the canvas to a Blob
                canvas.toBlob(
                    (blob) => {
                        if (blob) {
                            resolve(blob);
                        } else {
                            reject(new Error('Compression failed'));
                        }
                    },
                    'image/jpeg', // Output format
                    quality // Quality of the output image (0.0 - 1.0)
                );
            };

            img.onerror = (error) => reject(error);
        };

        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
}

useEffect(() => {
  if (mapContainerRef.current && !mapRef.current) {
    // Create the Leaflet map centered at the order coordinates.
    mapRef.current = L.map(mapContainerRef.current, {
      center: [order.latitude, order.longitude],
      zoom: 15,
      zoomControl: false, // Disables the default zoom control if desired.
    });
    // Add an OpenStreetMap tile layer.
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; OpenStreetMap contributors',
    }).addTo(mapRef.current);
    
    // Create a custom red icon similar to Google's red dot.
    const orderIcon = L.icon({
      iconUrl: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png',
      iconSize: [32, 32],
      iconAnchor: [16, 32], // bottom center of the icon.
    });
    
    // Add the order marker.
    const orderMarker = L.marker([order.latitude, order.longitude], {
      icon: orderIcon,
      title: 'Order Location',
    }).addTo(mapRef.current);
    setOrderPointMarker(orderMarker);
  }
}, [order]);

  useEffect(() => {
    const fetchDriverLocation = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/location/driver`, {
          params: {
            driver_id: order.driver_id, // Send the driver_id as a query parameter
          },
        });
        console.log('Driver location:', response.data);

        // Update the driver state with the new data
        setDriver(response.data);
      } catch (error) {
        console.error('Error fetching driver location:', error);
      }
    };

    // Fetch the driver location every 4 seconds
    const intervalId = setInterval(fetchDriverLocation, 4000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [order.driver_id]);

  useEffect(() => {
    if (driver && driver.latitude && driver.longitude && mapRef.current) {
      const { latitude, longitude } = driver;
      
      // Create a custom icon for the driver marker.
      const driverIcon = L.icon({
        iconUrl: `${process.env.PUBLIC_URL}/Copia de Fast-Max-Isotipo (1).png`,
        iconSize: [30, 30],
        iconAnchor: [15, 30], // bottom center
      });
      
      if (currentLocationMarker) {
        // Update the marker's position.
        currentLocationMarker.setLatLng([latitude, longitude]);
      } else {
        // Create a new driver marker.
        const driverMarker = L.marker([latitude, longitude], {
          icon: driverIcon,
          title: "Driver Location",
        }).addTo(mapRef.current);
        setCurrentLocationMarker(driverMarker);
      }
      
      // Optionally, pan the map to keep the driver's marker visible:
      // mapRef.current.panTo([latitude, longitude]);
    }
  }, [driver]); // Re-run this effect whenever the driver state updates



  const handleCallClick = () => {
    window.open(`tel:${order.telefono}`, '_self');
  };

  // Function to initiate a WhatsApp chat
  const handleWhatsAppClick = () => {
    const message = encodeURIComponent("Hola, estoy en camino para recoger tu pedido.");
    window.open(`https://wa.me/${order.telefono}?text=${message}`, '_blank');
  };

  const handleDirectionsClick = () => {
    const { latitude, longitude } = order;
    const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    window.open(googleMapsUrl, '_blank');
  };

  const handleMessageClick = async (id, type) => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/update_tres_minutos`, {
            id: id,
            type: type  // either 'order' or 'delivery'
        }, {
            headers: { Authorization: `Bearer ${token}` }
        });

        if (response.status === 200) {
            console.log(`${type} tres_minutos updated successfully`);
            // Optionally, you can refresh the data or update the UI to reflect this change
            window.alert(`Mensaje eviado con exito`);
          } else {
            console.error(`Failed to update ${type} tres_minutos`);
        }
    } catch (error) {
        console.error(`Error updating ${type} tres_minutos:`, error);
    }
  };
    
    
  const handleCameraClick = async () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.capture = 'camera';
    fileInput.multiple = true;

    fileInput.onchange = async () => {
        const selectedFiles = Array.from(fileInput.files);

        try {
            // Compress the images
            const compressedImages = await Promise.all(selectedFiles.map(async (file) => {
                const compressedFile = await compressImage(file, 0.6);
                return compressedFile;
            }));

            setImages([...images, ...compressedImages]);
        } catch (error) {
            console.error("Error compressing images: ", error);
        }
    };

    fileInput.click();
};

const handleSubmit = async () => {
  if (!note || !status || images.length === 0) {
    alert("Por favor asegúrese de proporcionar una nota, seleccionar un estado y cargar al menos una imagen antes de enviar.");
    return;
  }

  const formData = new FormData();
  formData.append('note', note);
  formData.append('status', status);
  images.forEach((image, index) => {
    formData.append('images', image, `image-${index}.jpg`);
  });

  setLoading(true);  // <-- Set loading to true when submission starts

  try {
    const token = localStorage.getItem('token'); 
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/driver_order_submit/${order.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      }
    });

    console.log('Order submitted successfully:', response.data);
    onBackClick();  // <-- Navigate back after successful submission
  } catch (error) {
    console.error('Error submitting order:', error);
    alert('There was an error submitting your order. Please try again.');
  } finally {
    setLoading(false);  // <-- Set loading to false when submission is complete
  }
};


    return (
      <Box>
        {/* First Box - Customer Name and Address */}
        <Box
  sx={{
    height: '20vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    alignItems: 'center'  // Ensure the icon and text are centered vertically
  }}
>
  {/* SupportAgentIcon on the left */}
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      paddingRight: '20px', // Add some space between the icon and the text
    }}
  >
    <PersonIcon style={{ fontSize: '3rem', color: 'black' }} />
  </Box>

  {/* Content Box with H2 and Back Icon */}
  <Box sx={{ width: '100%' }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <h2 style={{ margin: 0, color: 'black', flexGrow: 1 }}>{order.customer_name} {order.customer_id} {order.telefono}</h2>
      <ButtonBase onClick={onBackClick}>
        <ArrowBackIcon style={{ color: 'black' }} />
      </ButtonBase>
    </Box>
    <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginTop: '10px', // Add some spacing between the title and details
          }}
        >
          {/* Order details */}
          <Box>
            <p style={{ margin: 0, color: 'black' }}>{order.id} {order.address}</p>
            <p style={{ margin: 0, color: 'black' }}>{order.order_notes}</p>
          </Box>

          {/* Arrival time on the right side */}
          <Box
            sx={{
              textAlign: 'right',
              color: 'black',
              fontSize: '1rem',
            }}
          >
            <p style={{ margin: 0 }}>Hora de llegada</p>
            <p style={{ margin: 0 }}>{formattedArrivalTime}</p>
          </Box>
        </Box>
  </Box>
</Box>
  
        {/* Second Box - Map */}
        <Box
        ref={mapContainerRef}
        sx={{
          height: '80vh',
          position: 'relative',
        }}
      >
        {loading ? (  // <-- Conditional rendering based on loading state
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <CircularProgress /> {/* <-- Show a loading spinner */}
            <p style={{ color: 'black' }}>Cargando, espera chamo...</p>
          </Box>
        ) : (
          // Map and other content...
          <Box>
            {/* The map will render here */}
          </Box>
        )}
      </Box>
        <IconButton
        style={{
          position: "absolute",
          bottom: "68%",
          left: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleCallClick}
        
      >
        <CallIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "58%",
          left: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleWhatsAppClick}
      >
        <WhatsAppIcon />
      </IconButton>
        
      <IconButton
        style={{
          position: "absolute",
          bottom: "44%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "54%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleNoteClick}
      >
        <NoteAddIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "48%",
          left: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleDirectionsClick}
      >
        <DirectionsIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "38%",
          left: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={() => handleMessageClick(order.id, 'order')}
      >
        <AccessAlarmIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "34%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleCameraClick}
      >
        <CameraAltIcon />
      </IconButton>
        <IconButton
        style={{
          position: "absolute",
          bottom: "24%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "50%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.3)",
        }}
        onClick={handleStatusClick}
      >
        <SettingsIcon />
      </IconButton>
      <IconButton
        style={{
          position: "absolute",
          bottom: "14%",
          right: 30,
          zIndex: 1000,
          backgroundColor: "white",
          border: "2px solid black",
          borderRadius: "80%",
          color: "black",
          marginBottom: "10px",
          transform: "scale(1.7)",
        }}
        onClick={handleSubmit}
        disabled={loading}
      >
        <CheckIcon />
      </IconButton>
      <Dialog open={openNoteDialog} onClose={() => setOpenNoteDialog(false)}>
                <DialogTitle>Agrega un comentario</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Porfavor ingrese una nota para el pedido.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nota"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenNoteDialog(false)}>Cancelar</Button>
                    <Button onClick={handleNoteSubmit}>Guardar</Button>
                </DialogActions>
            </Dialog>

            {/* Dialog for Status Selection */}
            <Dialog open={openStatusDialog} onClose={() => setOpenStatusDialog(false)}>
                <DialogTitle> Estado</DialogTitle>
                <DialogContent>
                    <TextField
                        select
                        label=""
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        fullWidth
                        variant="outlined"
                    >
                        {statusOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenStatusDialog(false)}>Cancelar</Button>
                    <Button onClick={handleStatusSubmit}>Guardar</Button>
                </DialogActions>
            </Dialog>
      </Box>
    );
}

export default OrderDriver;